<template>
    <v-app>
        <div id="app">
            <Sidebar />
            <PopupNotification
                v-show="
                    this.$store.state.isVisiblePopupNotification &&
                    $route.path !== '/' &&
                    $route.path !== '/desktop_plug'
                "
            />
            <router-view />
        </div>
    </v-app>
</template>

<script>
import "./style.css";

import { mapActions } from "vuex";

import PopupNotification from "./components/Popup-notification.vue";
import Sidebar from "./components/Sidebar-menu.vue";

export default {
    components: { Sidebar, PopupNotification },
    data() {
        return {
            isMobile:
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
                    navigator.userAgent
                ),
            windowWidth: window.innerWidth,
        };
    },
    methods: {
        ...mapActions({
            getUserData: "getUserData",
            getUserNotification: "getUserNotification",
            getQrcodes: "getQrcodes",
        }),
        onResize() {
            this.windowWidth = window.innerWidth;
            this.redirect();
        },
        redirect() {
            if (
                !/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                this.$router.push({ name: "desktop_plug" });
            }
        },
    },
    watch: {
        "$route.path": function () {
            this.redirect();
        },
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener("resize", this.onResize);
        });
        this.onResize();
        this.redirect();
        this.getUserData();
        this.getQrcodes();
        this.getUserNotification();
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResize);
    },
};
</script>
