<template>
    <div>
        <!-- <v-form ref="form" lazy-validation v-if="!recovery"> -->
        <div class="title__wrap">
            <h1>Вход и регистрация</h1>
        </div>
        <form @submit.prevent="validateEmail">
            <div class="inputs">
                <v-text-field
                    v-model="valueEmail"
                    label="Email"
                    :rules="emailRules"
                    @input="updateInput"
                    autocomplete="email"
                    dense
                    outlined
                    class="email-field"
                    :class="{
                        'error-input': this.emailRules.length > 0,
                        'true-input': this.emailRules.length === 0 && emailTrue,
                    }"
                    required
                    clearable
                >
                </v-text-field>
            </div>
            <!-- :disabled="valueEmail==='' || valueEmail===null"      
                    v-on:keydown.space="()=>{
                        $event.preventDefault();
                    }"      
                    v-on:keypress.space="()=>{
                        $event.preventDefault();
                    }"-->
            <v-btn depressed :color="'primary'" class="next-btn" block @click="validateEmail">
                <div class="btn-content-container">
                    <img
                        src="@/assets/loader.svg"
                        alt="loader"
                        class="loader-icon"
                        :class="{ hidden: !isLoading }"
                    />

                    Далее
                </div>
            </v-btn>
        </form>
        <!-- </v-form> -->
        <div class="politic-checkbox">
            <p class="politic">
                Я принимаю условия
                <a href="https://cdn.kpk-promo.ru/pdf/terms-of-use.pdf" target="_blank"
                    >пользовательского соглашения</a
                >
                и соглашаюсь с&nbsp;<a
                    href="https://cdn.kpk-promo.ru/pdf/consent-to-the-processing-of-personal-data.pdf"
                    target="_blank"
                    >политикой обработки персональных данных</a
                >
            </p>

            <input type="checkbox" id="checkbox" required v-model="checked" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: String, required: true },
        isLoading: { type: Boolean, required: true },
        validate: { type: Function, required: true },
    },
    data() {
        return {
            checked: false,
            valueEmail: this.value,
            prevValue: "",
            emailRules: [],
            emailTrue: false,
        };
    },
    methods: {
        validateEmail() {
            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (pattern.test(this.valueEmail)) {
                this.emailRules = [];

                if (!this.checked) {
                    this.emailRules = [
                        "Подтвердите согласие с политикой обработки персональных данных",
                    ];
                } else this.validate();
            } else {
                this.emailRules = ["проверьте email"];
            }
        },
        async spaceBlock(e) {
            await this.$nextTick();
            console.log(e);
            // console.log(e.keyCode === 32)
            // console.log(this.valueEmail)
        },
        async updateInput(event) {
            if (event.split("").includes(" ")) {
                await this.$nextTick();
                console.log(1);
                this.valueEmail = this.prevValue;
                this.emailRules = [];
            } else {
                await this.$nextTick();
                console.log(2);
                this.prevValue = this.valueEmail;
                await this.$nextTick();
                this.valueEmail = event;
                await this.$nextTick();
                this.$emit("input", event);
                this.emailRules = [];
            }
        },
    },
    watch: {
        valueEmail() {
            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (pattern.test(this.valueEmail)) {
                // console.log(true)
                this.emailTrue = true;
            } else {
                // console.log(false)
                this.emailTrue = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    width: 100%;
    margin: 88px 0 50px 0 !important;
    padding: 0 16px !important;
}

.title__wrap {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 54px;
}

.v-form h1 {
    font-family: "PT Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.005em;

    color: #1a1a1a;
}

.v-form,
.form {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.v-form {
    background: #fff;
    width: 100%;
    padding: 32px 20px;
    border-radius: 0px;
}
.inputs {
    display: flex;
    position: relative;
    flex-direction: column;
    // margin-bottom: 28px;
    gap: 20px;
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    margin-bottom: 6px !important;
}
.btn-content-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader-icon {
    transform: rotate();
    margin-right: 10px;
    visibility: visible;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loader-icon.hidden {
    visibility: hidden;
}

.next-btn {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-top: 2px;
    margin-bottom: 12px;
}
.politic-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.politic {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.015em;

    color: #868686;
}
.politic a {
    color: #868686;
}
.email-field .error--text {
    color: var(--main-color) !important;
}
</style>
