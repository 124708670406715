<template>
    <div class="scan v-form v-form__scan active">
        <div class="scan__elements">
            <video class="scanner"></video>
            <p class="scan-alertText">Убедитесь, что в области отчётливо виден QR код</p>

            <p class="recovery recovery-scan" @click="$router.push({ name: 'LcUpload' })">
                Не считывается QR код?
            </p>
        </div>
        <canvas id="canvas" style="overflow: auto"></canvas>
    </div>
</template>

<script>
import QrScanner from "qr-scanner";
import jwt_decode from "jwt-decode";
import { mapActions } from "vuex";
import axios from "axios";

export default {
    data() {
        return {
            qrScanner: null,
            user_id: jwt_decode(this.$cookies.get("cng_token"))["id"],
            isLoading: false,
        };
    },
    methods: {
        startScan() {
            if (!this.qrScanner) {
                let videoElem = this.$el.querySelector(".scanner");
                this.qrScanner = new QrScanner(
                    videoElem,
                    async (result) => {
                        let res = this.decodeStr(result.data);
                        if (!res) {
                            return;
                        }
                        if (!res.fn || !res.fp || !res.i || !res.n || !res.s || !res.t) {
                            return;
                        }
                        function dataURItoBlob(dataURI) {
                            var byteString;
                            if (dataURI.split(",")[0].indexOf("base64") >= 0)
                                byteString = atob(dataURI.split(",")[1]);
                            else byteString = unescape(dataURI.split(",")[1]);
                            // separate out the mime component
                            var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
                            // write the bytes of the string to a typed array
                            var ia = new Uint8Array(byteString.length);
                            for (var i = 0; i < byteString.length; i++) {
                                ia[i] = byteString.charCodeAt(i);
                            }
                            return new Blob([ia], { type: mimeString });
                        }
                        var canvas = document.getElementById("canvas");
                        var video = document.querySelector("video");
                        canvas.width = video.videoWidth;
                        canvas.height = video.videoHeight;
                        canvas
                            .getContext("2d")
                            .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                        this.selectedFile = dataURItoBlob(canvas.toDataURL());
                        this.qrScanner.stop();
                        let formData = new FormData();
                        formData.append("file", this.selectedFile);
                        formData.append("account_id", this.user_id);
                        formData.append("time", res.t);
                        formData.append("operation", res.n);
                        formData.append("summary", res.s);
                        formData.append("fd", res.i);
                        formData.append("fn", res.fn);
                        formData.append("fp", res.fp);
                        formData.append("type", "scan");

                        try {
                            await axios.post(`${process.env.VUE_APP_QR}`, formData);
                            
                            console.log("🚀 ~ formData:", formData)
                            this.status = "success";
                            this.$router.push({ name: "LcSuccess" });
                        } catch (error) {
                            console.error(error.message);
                            this.qrScanner.start();
                            return;
                        }
                    },
                    { returnDetailedScanResult: true }
                );
            }
            this.qrScanner.start();
        },
        decodeStr(str) {
            console.log(str);
            try {
                let kv = str.split("&");
                let res = {};
                for (let i = 0; i < kv.length; i++) {
                    let tmp = kv[i].split("=");
                    res[tmp[0]] = tmp[1];
                }
                return res;
            } catch (e) {
                console.log(e);
                return false;
            }
        },
        ...mapActions({
            getUserNotification: "getUserNotification",
        }),
    },
    mounted() {
        this.startScan();
    },
    destroyed() {
        this.qrScanner.stop();
    },
};
</script>

<style scoped>
.v-form__scan {
    padding-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 47px 40px 32px 40px; */
}

.scan {
    display: none;
}
.scan.active {
    display: block;
    position: relative;
}

.scan-alertText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.015em;

    color: #1a1a1a;
}

.recovery-scan {
    font-family: "Inter" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 100% !important;
    text-align: center !important;
    letter-spacing: -0.015em !important;

    /* Blue/50 */

    color: var(--main-color) !important;
    margin-top: -38px !important;
    margin-top: 12px !important;
}

.scanner {
    width: 255.66px !important;
    height: 253px !important;
    background: #dadada;
    border: 1.5px solid var(--main-color);
    border-radius: 8px;
    margin-bottom: 48px;
}

.scan__elements {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0px !important;
    align-items: center;
}

.demo-module {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
}

.demo-notification {
    background: var(--second-color);
    padding: 10px 25px;
    margin-bottom: 12px;
    border-radius: var(--border-radius);
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.015em;

    color: #ffffff;
}

.scan.active::before,
.scan.active::after,
.scan__elements::before,
.scan__elements::after {
    content: "";
    position: absolute;
    background-image: url("../../assets/angle.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 29px;
    height: 29px;
    z-index: 1;
}

.scan__elements::before {
    top: -17px;
    left: -0px;
    transform: rotate(90deg);
}

.scan__elements::after {
    top: 240px;
    left: -0px;
}

.scan.active::before {
    top: 32px;
    right: 20px;
    transform: rotate(180deg);
}

.scan.active::after {
    top: 289px;
    right: 20px;
    transform: rotate(270deg);
}
</style>
